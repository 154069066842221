// ignolre-string-externalization

import React from 'react';
import { ModuleType } from '../types';

import { ContentGrid } from '../../ContentGrid';
import { CTALink } from '../../ContentGrid/index.styles';
import { fixHref } from '../../utils';
import {
  makeColorSetClass,
  IconArrowRight,
} from '@spotify-internal/encore-web';

type EditorialGridModuleType = ModuleType & {
  headline?: string;
  subtext?: string;
  items?: any[];
  ctaText?: string;
  ctaLink?: string;
};

const TRACK_CATEGORY = 'module-editorialGrid';
export const EditorialGridModule = React.forwardRef<
  React.MutableRefObject<ModuleType>,
  EditorialGridModuleType
>(({ id, headline, subtext, items = [], ctaText, ctaLink, themeName }, ref) => {
  return (
    <div
      className={makeColorSetClass(
        themeName === 'blackOnWhite' ? 'base' : 'invertedDark',
      )}
    >
      <ContentGrid
        ref={ref}
        trackCategory={TRACK_CATEGORY}
        data-testid={`editorial-grid-module-${id}`}
        headline={headline}
        subtext={subtext}
        items={items}
        featuredItems={2}
        cta={
          ctaLink && ctaText ? (
            <CTALink
              href={fixHref(ctaLink)}
              track={[TRACK_CATEGORY, `CTA Link - ${ctaText}`]}
              iconTrailing={() => <IconArrowRight iconSize={16} />}
            >
              {ctaText}
            </CTALink>
          ) : undefined
        }
      />
    </div>
  );
});

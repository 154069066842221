// ignore-string-externalization

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { MODULE_THEMES, TEMPLATE_THEMES } from '../Theme';
import { Box, Stack } from '../ui';
import { Module } from '../Module';
import { TemplateType } from '.';
import { getAuthorDateString } from '../utils';
import { ArticleModuleType } from '../Module/ArticleModule';
import { useDynamicNavBarColor } from './useDynamicNavBarColor';
import { ContentfulAsset } from '../Contentful';
import { ModuleType } from '../Module/types';

export type ArticleTemplateType = TemplateType & {
  headerModule?: ModuleType;
  introduction?: string;
  article?: string;
  video?: ModuleType;
  modules?: ModuleType[];
  relatedStories?: ModuleType;
  recirculationModule?: ModuleType;
  author?: string;
  releaseDate?: Date;
  initialDropcap?: boolean;
  thumbnailImage?: ContentfulAsset;
};

export const ArticleTemplate: React.FC<ArticleTemplateType> = template => {
  const {
    theme = 'Dark',
    headerModule,
    introduction,
    article,
    video,
    modules = [],
    author,
    releaseDate = '',
    initialDropcap = false,
  } = template;

  const releaseDateParsed: Date = new Date(releaseDate);

  const releaseDateFormatted = releaseDateParsed.toLocaleDateString(undefined, {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
  });

  if (headerModule) {
    headerModule.releaseDate = releaseDateFormatted;
    headerModule.author = author;
  }

  let articleModule;
  if (article) {
    articleModule = {
      contentType: 'articleModule',
      introduction,
      author,
      releaseDate: releaseDateFormatted,
      body: article,
      initialDropcap,
    } as ArticleModuleType;
  }

  const tplTheme = TEMPLATE_THEMES[theme];

  const modTheme = (mod: ModuleType) => ({
    ...tplTheme,
    ...(MODULE_THEMES[mod.themeName || ''] || {}),
  });
  const isFullBleed = (mod: ModuleType) =>
    [
      'videoModule',
      'videoModuleLongBody',
      'relatedStoriesList',
      'articleHeaderModule',
      'articleModule',
      'featuredContentModule',
    ].includes(mod.contentType);

  const headerVideo = { ...video, isHeader: true };

  const allModules: any = [
    headerModule,
    articleModule,
    headerVideo,
    ...(modules || []),
  ].filter(m => Boolean(m?.contentType));

  useDynamicNavBarColor(allModules?.[0]);

  const renderModule = React.useCallback((mod: ModuleType) => {
    const modProps = { ...mod } as any;
    modProps.authorDateString = getAuthorDateString(
      modProps.author,
      modProps.releaseDate,
    );
    return (
      <div>
        <Module {...modProps} shouldAnimate={false} />
      </div>
    );
  }, []);

  return (
    <Stack data-testid={`landing-${template.urlSlug || 'unknown'}`}>
      {allModules.map((mod: ModuleType, idx: number) => (
        <ThemeProvider key={`${mod.id}-${idx}`} theme={modTheme(mod)}>
          <Box noPadding={isFullBleed(mod)}>{renderModule(mod)}</Box>
        </ThemeProvider>
      ))}
    </Stack>
  );
};

import React from 'react';
import {
  screenSmMax,
  screenSmMin,
  cssSpacing,
} from '@spotify-internal/encore-web';
import { Grid, SectionHeader } from '@spotify-internal/frodor-modules';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { useLocale, useT } from '../../../features/src/i18n';
import { useTracking } from '../Tracking';
import type { SearchHit } from '../Search/types';
import { itemToSearchHit } from '../Search/content';
import { ModuleType } from '../Module/types';
import { LoadMoreButtonWrapper } from './index.styles';
import { LoadingButton } from './LoadingButton';
import { ItemList } from './ItemList';

type ContentGridType = {
  trackCategory: string;
  supertext?: string;
  headline?: string;
  subtext?: string;
  cta?: JSX.Element;
  items?: any[];
  featuredItems?: 0 | 2;
  getPageItems?: (page: number) => Promise<any[]>;
  hasNextPage?: (curPage: number) => boolean;
  placeholders?: boolean;
};

/**
 * This is a version of a Grid that does two things
 * 1. It adds a 40px padding to the top to make space for the header
 * 2. It adds a fade in effect for the children
 *
 * There is untapped potential to reuse / standardize those in other components
 */
export const StyledGrid = styled(Grid)<{ displayModule: boolean }>`
  padding-top: ${cssSpacing('looser-2')};
  padding-bottom: ${cssSpacing('base')};

  /* Two column (tablet) grid */
  @media (min-width: ${screenSmMin}) and (max-width: ${screenSmMax}) {
    padding-bottom: ${cssSpacing('tighter-2')};
  }

  /*
     This Hides the entire module when the box is empty.
     I think it was added for animation
  */
  height: ${props => (props.displayModule ? '' : '0px')};
  padding: ${props => (props.displayModule ? '' : '0px')};
  opacity: ${props => (props.displayModule ? '' : '0%')};
`;

/**
 * The header spans the whole grid
 */
const FullWidthSectionHeader = styled(SectionHeader)`
  grid-column: 1/-1;
  padding-bottom: ${cssSpacing('looser-2')};
  @media (max-width: ${screenSmMax}) {
    padding-bottom: ${cssSpacing('looser')};
  }
`;

/**
 * The item list spans the whole grid.
 * The ItemList itself is also a grid, unrelated to this one.
 */
const FullWidthItemList = styled(ItemList)`
  grid-column: 1 / -1;
`;

/**
 * This component does a lot.
 * Probably too much.
 *
 * Possible refactor opportunities:
 * - Leverage children to create a header + children component (GridWithHeader?)
 * - Just get rid of this component and reimplement its children with less logic
 * - Pull out the business logic into a parent (translate, page loading, etc.)
 */
export const ContentGrid = React.forwardRef<
  React.MutableRefObject<ModuleType>,
  ContentGridType
>(
  (
    {
      supertext,
      headline,
      subtext,
      cta,
      trackCategory,
      items: initialItems = [],
      featuredItems = 0,
      getPageItems = () => Promise.resolve([]),
      hasNextPage = () => false,
      placeholders = true,
    },
    ref,
  ) => {
    const t = useT();
    const locale = useLocale();
    const router = useRouter();
    const { sendEvent } = useTracking();
    /**
     * This is very strange, and should be fixed
     * This should be more generic, and not coupled to the SearchHit structure.
     * At the very least, that should be extracted from SearchHit, and renamed
     */
    const [items, setItems] = React.useState<SearchHit[]>([]);
    const page = React.useRef(1);
    const [pageLoading, setPageLoading] = React.useState(false);

    React.useEffect(() => {
      const newItems = initialItems
        .map(item => itemToSearchHit(item, locale))
        .filter(Boolean) as NonNullable<SearchHit[]>;
      setItems(newItems);
    }, [initialItems]);

    const loadNextPage = async (e: React.MouseEvent) => {
      e.preventDefault();
      setPageLoading(true);
      sendEvent({
        eventCategory: `Frodor - ${trackCategory}`,
        eventAction: 'click',
        eventLabel: `Load More - Page ${page.current + 1}`,
      });
      const pageItems = await getPageItems(++page.current);
      const newItems = pageItems
        .map(item => itemToSearchHit(item, locale))
        .filter(Boolean) as NonNullable<SearchHit[]>;
      setItems([...items, ...newItems]);
      setPageLoading(false);
    };

    return (
      <StyledGrid ref={ref} displayModule={items?.length !== 0}>
        <FullWidthSectionHeader
          eyebrow={supertext}
          heading={headline || ''}
          body={subtext}
          cta={cta}
        />
        <FullWidthItemList
          featuredItems={featuredItems}
          items={items}
          trackCategory={trackCategory}
          placeholders={placeholders}
        />
        {(hasNextPage(page.current) && Boolean(items?.length)) ||
          (router.query && router.query._lqa === 'loadMore' && (
            <LoadMoreButtonWrapper>
              <LoadingButton loading={pageLoading} onClick={loadNextPage}>
                {t(
                  'FRODOR_CONTENT_GRID_LOAD_MORE',
                  'Load more',
                  'If clicked, this button will show the next page of content',
                )}
              </LoadingButton>
            </LoadMoreButtonWrapper>
          ))}
      </StyledGrid>
    );
  },
);

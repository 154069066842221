import React from 'react';
import styled from 'styled-components';
import {
  LoadingIndicator,
  screenMdMin,
  screenSmMax,
  screenSmMin,
  screenXsMax,
  cssSpacing,
} from '@spotify-internal/encore-web';
import { SearchHit } from '../Search/types';
import { ContentGridItem } from './ContentGridItem';
import { Grid } from '@spotify-internal/frodor-modules';

export const ItemListWrap = styled(Grid).attrs({
  fullBleed: true,
})`
  @media (max-width: ${screenXsMax}) {
    padding: 0;
  }
`;

/* Several grid layouts provided by design */
export const FeaturableGridItem = styled(ContentGridItem)`
  grid-column: 1 / -1;
  margin-bottom: ${cssSpacing('looser-2')};

  /* The featured grid full size */
  @media (min-width: ${screenMdMin}) {
    grid-column: auto / span ${({ featured }) => (featured ? '6' : '4')};
    margin-bottom: ${cssSpacing('looser-4')};
  }

  /* Two column (tablet) grid */
  @media (min-width: ${screenSmMin}) and (max-width: ${screenSmMax}) {
    grid-column: auto / span 1;
    margin-bottom: ${cssSpacing('looser-4')};
  }
`;

export type ItemListProps = {
  items: SearchHit[];
  featuredItems: 0 | 2;
  trackCategory: string;
  placeholders: boolean;
  className?: string;
};

/**
 * Pass a list of items, and this component will rendern them in various configuartions based on the screen size.
 * Uses css grid, so should probably be renamed to ItemGrid.
 *
 * This component is too complicated, and should be refactored to handle generic items, and defer the Item specific logic to a parent.
 *
 * @param featuredItems The number of items to "feature" (probably should just be a boolean)
 * @param items The items to display in the list (probably should just be generic JSX.Elements)
 * @param trackCategory This is just a drilled prop, if items are generic, it can be handled in the parent
 * @param placeholders This is just a drilled prop, if items are generic, it can be handled in the parent
 * @param className used to extend the ItemListWrap styled-component
 */
export const ItemList: React.FC<ItemListProps> = ({
  items,
  featuredItems,
  trackCategory,
  placeholders,
  className,
}) => {
  return (
    <ItemListWrap className={className}>
      {(!items && <LoadingIndicator />) ||
        items.map((item, index) => (
          <FeaturableGridItem
            item={item}
            key={item.id}
            featured={index < featuredItems}
            trackCategory={trackCategory}
            placeholders={placeholders}
          />
        ))}
    </ItemListWrap>
  );
};

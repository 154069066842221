// ignore-string-externalization

import styled from 'styled-components';
import { hexToRgba } from '../../../../features/src/color-conversion';
import {
  cssSpacing,
  white,
  black,
  screenSmMin,
  screenSmMax,
  screenXsMax,
  ButtonTertiary,
} from '@spotify-internal/encore-web';
import { Crossfade } from '@spotify-internal/frodor-modules';
import { transformScaleUp } from '../../utils';
import { MAX_CONTENT_WIDTH, MAX_SCREEN_WIDTH } from '../../constants';

export const Carousel = styled.div<{ isHeader?: boolean; tallest?: number }>`
  position: relative;
  height: ${({ tallest }) => `${tallest ? tallest + 50 : 100}px`};
  /* always display arrows on hover */
  &:hover button svg {
    opacity: 0.4;
    display: initial;
  }
`;

export const Splash = styled.div<{ tallest?: number }>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${white};
  opacity: ${({ tallest }) => (tallest && tallest < 2 ? 1 : 0)};
  z-index: ${({ tallest }) => (tallest && tallest < 2 ? 9999 : -1)};
  transition:
    opacity 0s 0.5s,
    z-index 0s 0.5s;
`;

export const Slides = styled.div`
  height: 100%;
`;

export const Slide = styled.div<{ active: boolean; backgroundColor: string }>`
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  background: ${({ backgroundColor }) => backgroundColor};

  visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
  @media (min-width: ${MAX_SCREEN_WIDTH}) {
    padding-left: calc((100% - ${MAX_CONTENT_WIDTH}) / 2);
    padding-right: calc((100% - ${MAX_CONTENT_WIDTH}) / 2);
  }
  ${Crossfade}
`;

export const Dots = styled.div`
  direction: ltr;
  position: absolute;
  bottom: ${cssSpacing('tighter-4')};
  left: ${cssSpacing('looser-4')};
  display: flex;
  flex-direction: row;
  z-index: 2;

  @media (max-width: ${screenXsMax}) {
    bottom: ${cssSpacing('tighter-4')};
    left: ${cssSpacing('tighter')};
  }

  @media (min-width: ${screenSmMin}) {
    bottom: ${cssSpacing('looser-4')};
    left: ${cssSpacing('looser-4')};
  }
  @media (min-width: ${MAX_SCREEN_WIDTH}) {
    padding-left: calc((100% - ${MAX_CONTENT_WIDTH}) / 2);
    padding-right: calc((100% - ${MAX_CONTENT_WIDTH}) / 2);
  }
`;

export const Dot = styled.div<{ active: boolean; color?: string }>`
  background: ${({ active, theme, color }) =>
    active
      ? getDotColor(color || theme.nav.background)
      : hexToRgba(getDotColor(color || theme.nav.background), 0.25)};
  border-radius: 50%;
  width: ${cssSpacing('tighter-2')};
  height: ${cssSpacing('tighter-2')};
  margin-left: ${cssSpacing('tighter-4')};
  margin-right: ${cssSpacing('tighter-4')};
  &:hover {
    cursor: pointer;
  }
`;

export const Arrow = styled(ButtonTertiary)<{ isUsingKeyboard?: boolean }>`
  direction: ltr;
  position: absolute;
  top: 15%;
  height: 70%;
  width: ${cssSpacing('tighter-4')};
  color: ${({ theme, color }) => getDotColor(color || theme.nav.background)};
  z-index: 2;
  &::after,
  &:focus::after {
    border-bottom: 0;
  }

  svg {
    display: none;
    position: absolute;
    top: calc(50% - 16px);
  }

  &:hover,
  &:not(:disabled):focus {
    svg {
      display: initial;
      color: ${({ theme, color }) =>
        hexToRgba(getDotColor(color || theme.nav.background), 0.75)};
      ${transformScaleUp};
      &:hover {
        opacity: 1;
      }
    }
  }

  &:not(:disabled):focus {
    svg {
      border-bottom: ${({ isUsingKeyboard }) => (isUsingKeyboard ? 3 : 0)}px
        solid
        ${({ theme, color }) => getDotColor(color || theme.nav.background)};
    }
  }

  @media (max-width: ${screenSmMax}) {
    display: none;
  }
`;

export const PreviousArrow = styled(Arrow)`
  left: ${cssSpacing('tighter-4')};
  svg {
    left: 0;
  }
  @media (min-width: ${MAX_SCREEN_WIDTH}) {
    padding-left: calc((100% - ${MAX_CONTENT_WIDTH}) / 2);
    padding-right: calc((100% - ${MAX_CONTENT_WIDTH}) / 2);
  }
`;

export const NextArrow = styled(Arrow)`
  right: ${cssSpacing('tighter-4')};
  width: 150px;
  svg {
    right: 0;
  }
  @media (min-width: ${MAX_SCREEN_WIDTH}) {
    padding-left: calc((100% - ${MAX_CONTENT_WIDTH}) / 2);
    padding-right: calc((100% - ${MAX_CONTENT_WIDTH}) / 2);
  }
`;

function getDotColor(hexColor: string) {
  // rounds color to black or white based on brightness level
  const color =
    hexColor.charAt(0) === '#' ? hexColor.substring(1, 7) : hexColor;
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);
  return r * 0.299 + g * 0.587 + b * 0.114 < 125 ? white : black;
}

// ignore-string-externalization

import { useCallback, useEffect } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { ModuleType } from '../Module/types';
import { defaultTheme } from '../Theme';
import { useLayoutContext } from '../Layout';
import { cssColorValue } from '@spotify-internal/encore-web';

export const useDynamicNavBarColor = (module?: Partial<ModuleType>) => {
  const { setNavBarBackground, setNavBarTextColor } = useLayoutContext();

  const updateNavBar = useCallback(
    (mod?: Partial<ModuleType>) => {
      // look at the mod type and infer colors
      if (
        typeof mod !== 'undefined' &&
        mod.contentType &&
        mod.contentType.toString() === 'jumpLinkHeroModule'
      ) {
        const bk =
          mod.colors?.backgroundColor || cssColorValue('backgroundBase');
        const fg = mod.colors?.textColor || cssColorValue('textBase');
        setNavBarBackground(bk);
        setNavBarTextColor(fg);
      } else {
        setNavBarBackground(
          mod?.colors?.backgroundColor ||
            mod?.backgroundColor ||
            defaultTheme.nav.background,
        );
        setNavBarTextColor(
          mod?.colors?.textColor || mod?.textColor || defaultTheme.nav.text,
        );
      }
    },
    [setNavBarTextColor, setNavBarBackground],
  );
  useEffect(() => {
    updateNavBar(module);
  }, [module, updateNavBar]);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (window.innerWidth > 992) {
        // desktop widths and above
        if (currPos.y < prevPos.y && prevPos.y === 0) {
          // scrolling down: reset nav bar to default colors
          updateNavBar();
        } else if (currPos.y > prevPos.y && currPos.y > -1) {
          // scrolling return to 0: reset nav bar colors to first module
          updateNavBar(module);
        }
      } else {
        // mobile widths
        if (currPos.y > prevPos.y) {
          currPos.y <= -56 ? updateNavBar() : updateNavBar(module);
        }
      }
    },
    [module],
  );
};

// ignore-string-externalization

import { createLoader } from '@spotify-internal/creator-data-loading';
import type { SearchResponse, SearchQuery, SearchHit } from './types';
import { fetchEntryList } from '../Contentful';
import { itemToSearchHit } from './content';

const searchLoaderContentful = createLoader<SearchQuery, SearchResponse>(
  async (query: SearchQuery): Promise<SearchResponse> => {
    const { limit = 10, skip = 0, contentTypes, uid } = query;

    const q = query.query;
    const language = query.locale || 'en-US';

    let params;
    if (contentTypes?.length === 1 && contentTypes?.[0] === 'faq') {
      params = {
        contentType: 'faq',
        limit,
        skip,
        locale: language,
        filters: {
          'indexed[nin]': false,
          'question[exists]': true,
          'answer[exists]': true,
        },
        select: ['fields.question', 'fields.urlSlug'],
        query: q,
      };
    } else {
      params = {
        limit,
        skip,
        query: q,
      };
    }

    try {
      const resp = await fetchEntryList(params);

      return {
        uid,
        hits: resp.entries
          .map(item => itemToSearchHit(item))
          .filter(Boolean)
          .map(hit => ({
            ...hit,
            searchUid: uid,
          })) as NonNullable<SearchHit>[],
        limit: resp.limit,
        skip: resp.skip,
        query: q,
        total: resp.total,
      };
    } catch (e: any) {
      return {
        uid,
        hits: [],
        total: 0,
        limit: 0,
        skip: 0,
        query: q,
        error: e.message,
      };
    }
  },
  {
    cacheKeyFn: JSON.stringify,
  },
);

export function getSearchLoader() {
  return searchLoaderContentful;
}

const searchLoader = searchLoaderContentful;

export { searchLoader };

import React from 'react';
import { useRouter } from 'next/router';
import { ProgressDots } from '@spotify-internal/encore-web';
import { ContentGrid } from '../ContentGrid';
import { Box } from '../ui';
import type { SearchResponse } from './types';
import { useT } from '@spotify-internal/i18n-core';

type SearchResultsComponentProps = {
  response: SearchResponse;
  getPageItems?: (page: number) => Promise<any[]>;
  hasNextPage?: (curPage: number) => boolean;
};

export const SearchResultsComponent: React.FC<SearchResultsComponentProps> = ({
  response,
  getPageItems = () => Promise.resolve([]),
  hasNextPage = () => false,
}) => {
  const t = useT();
  const router = useRouter();
  if (
    !response?.hits?.length ||
    router.query._lqa === 'showSearchResultsEmpty'
  ) {
    return (
      <Box style={{ height: '100vh' }}>
        {response.query ? t('FRODOR_037507', 'No results', '') : ''}
      </Box>
    );
  }

  return (
    <>
      {(response && (
        <ContentGrid
          trackCategory="Search"
          subtext={`${response.total} results`}
          items={response.hits}
          getPageItems={getPageItems}
          hasNextPage={hasNextPage}
          featuredItems={0}
        />
      )) || <ProgressDots />}
    </>
  );
};

import React, { Ref, MutableRefObject } from 'react';
import styled from 'styled-components';
import {
  DELAYS,
  fadeInAnimation,
  Grid,
  withAnimationContext,
} from '@spotify-internal/frodor-modules';
import { hexToRgba } from '../../../../features/src/color-conversion';
import {
  Text,
  screenMdMin,
  screenSmMax,
  cssSpacing,
  IconArrowRight,
} from '@spotify-internal/encore-web';
import {
  Link,
  ButtonTertiaryAsLink,
  HeadlineWithSupertextComponent,
} from '../../ui';
import { ModuleType } from '../types';
import { useT } from '@spotify-internal/i18n-core';

const FAQLinks = styled(Grid)`
  background: ${({ theme }) => theme.box.background};
  color: ${({ theme }) => theme.box.text};
  display: flex;
  align-items: flex-start;
  padding-top: ${cssSpacing('looser')};
  padding-bottom: ${cssSpacing('looser')};

  @media (max-width: ${screenSmMax}) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;

  ul {
    list-style: none;
    li {
      padding-top: ${cssSpacing('base')};
      padding-bottom: ${cssSpacing('base')};
      &:not(:first-child):not(:last-child) {
        border-top: 1px solid ${({ theme }) => hexToRgba(theme.box.text, 0.25)};
      }
    }
  }
`;

const AllFAQLink = styled(ButtonTertiaryAsLink)`
  color: ${({ theme }) => theme.box.text};

  &:hover:not(:focus):not(:disabled) {
    color: ${({ theme }) => hexToRgba(theme.box.text, 0.25)};
  }
`;

const FAQList = withAnimationContext(styled.ul`
  ${fadeInAnimation(DELAYS.THIRD)};
  padding-left: 0px;
`);

const FAQ = styled(Text).attrs({ forwardedAs: 'p', variant: 'bodyMedium' })`
  color: ${({ theme }) => theme.box.text};
  padding: 0px;

  &:hover:not(:focus):not(:disabled) {
    color: ${({ theme }) => hexToRgba(theme.box.text, 0.25)};
  }

  @media (min-width: ${screenMdMin}) {
    text-align: left;
    justify-content: flex-end;
    width: 100%;
  }
  @media (max-width: ${screenSmMax}) {
    width: 100%;
    align-items: center;
  }
`;

export type FAQType = {
  question: string;
  urlSlug: string;
  answer: string;
  id: string;
  contentType: string;
};

export type FAQLinksModuleType = ModuleType & {
  supertext?: string;
  headline?: string;
  faqs?: FAQType[];
};

const TRACK_CAT = 'module-faqLinks';

export const FAQLinksModule = React.forwardRef<
  React.MutableRefObject<ModuleType>,
  FAQLinksModuleType
>(({ supertext = '', headline = '', faqs = [] }, ref) => {
  const t = useT();

  return (
    <FAQLinks ref={ref as Ref<MutableRefObject<ModuleType>>}>
      <Left>
        <HeadlineWithSupertextComponent
          supertext={supertext}
          headline={headline}
        />
      </Left>
      <Right>
        <FAQList>
          {faqs.map(faq => (
            <li key={faq.id}>
              <Link
                track={[TRACK_CAT, `FAQ Link - ${faq.question}`]}
                href={`/help/article/${faq.urlSlug}`}
              >
                <FAQ>{faq.question}</FAQ>
              </Link>
            </li>
          ))}
          <li>
            <AllFAQLink
              href="/help"
              track={[TRACK_CAT, 'All FAQ Link']}
              iconTrailing={() => <IconArrowRight iconSize={16} aria-hidden />}
              condensedAll
            >
              {t(
                'FRODOR_SEE_ALL_FAQS',
                'See all FAQs',
                'link to the help center containing frequently asked questions',
              )}
            </AllFAQLink>
          </li>
        </FAQList>
      </Right>
    </FAQLinks>
  );
});

import React from 'react';
import styled from 'styled-components';
import {
  IconLink,
  IconFacebook,
  IconTwitterX,
  white,
  cssSpacing,
} from '@spotify-internal/encore-web';
import { hexToRgba } from '../../../features/src/color-conversion';
import { Link } from './Link';
import { withoutProps } from '../utils';
import { useT } from '@spotify-internal/i18n-core';
import { useRouter } from 'next/router';

const InlineShareButtons = styled.div`
  padding-bottom: ${cssSpacing('looser')};
  color: ${({ theme }) => theme.cta.text};
  a:focus:not(:disabled) {
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

const SocialButton = styled(withoutProps(Link, 'textColor'))<{
  textColor?: string;
}>`
  padding-right: ${cssSpacing('base')};

  :last-of-type {
    padding-right: 0;
  }

  svg {
    color: ${({ textColor }) => (textColor ? textColor : white)};

    &:hover {
      color: ${({ textColor }) =>
        textColor ? hexToRgba(textColor, 0.75) : hexToRgba(white, 0.75)};
    }
  }
`;

type ShareButtonsType = {
  textColor?: string;
};

export const ShareButtonsComponent: React.FC<ShareButtonsType> = ({
  textColor = '',
}) => {
  const t = useT();
  const router = useRouter();
  const TRACK_CAT = 'frodor_share';
  const thisUrl = () => `https://artists.spotify.com${router.asPath}`;
  const facebookClick = () => {
    const facebookWindow = window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        thisUrl(),
      )}`,
      '',
      'width=560, height=750',
    );
    if (facebookWindow) facebookWindow.opener = null;
  };
  const twitterClick = () => {
    const twitterWindow = window.open(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(thisUrl())}`,
      '',
      'width=560, height=750',
    );
    if (twitterWindow) twitterWindow.opener = null;
  };
  function copyToClipboard() {
    navigator.clipboard.writeText(thisUrl());
  }
  return (
    <InlineShareButtons data-testid="share-buttons-container">
      <SocialButton
        external
        track={[TRACK_CAT, 'facebook']}
        textColor={textColor}
      >
        <IconFacebook
          onClick={facebookClick}
          aria-label={t(
            'FRODOR_SHARE_FACEBOOK',
            'Share on Facebook',
            'Link to share current page on Facebook',
          )}
          data-testid="share-buttons-facebook-icon"
        />
      </SocialButton>
      <SocialButton
        external
        track={[TRACK_CAT, 'twitter']}
        textColor={textColor}
      >
        <IconTwitterX
          onClick={twitterClick}
          aria-label={t(
            'FRODOR_SHARE_TWITTER',
            'Share on Twitter',
            'Link to share current page on Twitter',
          )}
          data-testid="share-buttons-twitter-icon"
        />
      </SocialButton>
      <SocialButton
        external
        track={[TRACK_CAT, 'link_copy']}
        textColor={textColor}
      >
        <IconLink
          onClick={copyToClipboard}
          aria-label={t(
            'FRODOR_SHARE_COPY_LINK',
            'Copy link to clipboard',
            'Link to copy current page URL to clipboard for sharing',
          )}
        />
      </SocialButton>
    </InlineShareButtons>
  );
};

// ignore-string-externalization

import type { SearchResponse } from './types';

export const PAGE_SIZE = 10;

export const EMPTY_SEARCH: SearchResponse = {
  hits: [],
  total: 0,
  skip: 0,
  limit: 0,
  query: '',
};

export const DEFAULT_SEARCH_LOCALE = 'en-US';

export { searchLoader } from './searchLoader';
export { SearchResultsComponent } from './SearchResultsComponent';
export { SearchBar } from './SearchBar';
export { itemToSearchHit } from './content';

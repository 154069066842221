// ignore-string-externalization

import { marked } from 'marked';
import memoize from 'lodash/memoize';
import { DefaultRenderer } from './DefaultRenderer';
import { ArticleRenderer } from './ArticleRenderer';

export const textIframeTransform = (text: string) =>
  text
    .replace(
      /<iframe.* src=".*youtube.com\/embed\/([^"]+)".*><\/iframe>/gi,
      '[youtube-video $1]',
    )
    .replace(
      /<iframe.* src=".*open.spotify.com\/embed\/([^"?]+).*[^extra-wide]><\/iframe>/gi,
      (_: any, p1: string) => `[play-button spotify:${p1.replace('/', ':')}]`,
    );

export const defaultMarkdown = memoize(text =>
  marked(text, { renderer: new DefaultRenderer() }),
);

export const articleMarkdown = memoize((text, initialDropcap = false) => {
  const renderer = new ArticleRenderer();
  renderer.initialDropcap = initialDropcap;

  // temporary? Replace manually embedded iframes with the correct
  // widget codes
  const txt = textIframeTransform(text);

  // Add class to picture paragraphs to distinguish them from text paragraphs
  // temporary? Consider moving this logic to the ArticleRenderer
  return marked(txt, { renderer }).replace(
    /<p><picture>/gi,
    '<p class="article-picture"><picture>',
  );
});

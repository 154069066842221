// ignore-string-externalization

import {
  Text,
  cssColorValue,
  screenSmMax,
  screenXsMax,
  screenXxsMax,
  screenXsMin,
  screenSmMin,
  cssSpacing,
} from '@spotify-internal/encore-web';
import {
  DELAYS,
  fadeInAnimation,
  withAnimationContext,
} from '@spotify-internal/frodor-modules';
import styled, { css } from 'styled-components';
import { hexToRgba } from '../../../features/src/color-conversion';
import { ImageAspectBox16x9, ButtonTertiaryAsLink, UnstyledLink } from '../ui';
import { transformScaleUp } from '../utils';

export const CTALink = styled(ButtonTertiaryAsLink)`
  padding-left: 0;
  text-align: left;
  color: ${props => props.theme.link?.color};
  svg {
    margin-left: ${cssSpacing('tighter-2')};
    vertical-align: -2px;
  }
  &&:hover:not(:disabled),
  &:focus:not(:disabled),
  &:active:not(:disabled) {
    color: ${props => hexToRgba(props.theme.link?.color, 0.5)};
    outline: none;
  }
`;

type ImageWrapperType = { video: boolean; featured: boolean };

export const ImageWrapper = styled(ImageAspectBox16x9)<ImageWrapperType>`
  overflow: hidden;

  /* for videos add a black gradient overlay at the bottom to make the play button
     slightly more visible */
  ${props =>
    props.video &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        box-shadow: inset 0 -90px 10px 0 rgba(0, 0, 0, 0.1);
        z-index: 2;
      }
    `}

  /* play icon */
  svg {
    display: ${props => (props.video ? 'initial' : 'none')};
    position: absolute;
    bottom: ${props =>
      props.featured ? cssSpacing('looser') : cssSpacing('base')};
    left: ${props =>
      props.featured ? cssSpacing('looser') : cssSpacing('base')};
    width: ${props => (props.featured ? '48px' : '32px')};
    height: auto;
    color: ${cssColorValue('backgroundBase')};
    z-index: 3;

    @media (max-width: ${screenXsMax}) {
      bottom: ${cssSpacing('tighter')};
      left: ${cssSpacing('tighter')};
    }

    @media (max-width: ${screenSmMax}) {
      bottom: ${cssSpacing('base')};
      left: ${cssSpacing('base')};
    }
  }

  &:hover {
    img,
    svg {
      ${transformScaleUp};
      transition: transform 0.3s ease-in-out;
    }
    svg {
      opacity: 0.8;
    }
  }
`;

export const ItemLabel = withAnimationContext(styled(Text)
  .attrs({
    variant: 'marginal',
    forwardedAs: 'p',
    weight: 'book',
  })
  .withConfig({
    shouldForwardProp: prop =>
      !['forwardedAs', 'animationContext'].includes(prop),
  })`
  ${fadeInAnimation(DELAYS.FOURTH)};
  padding-top: ${cssSpacing('looser')};
  padding-bottom: 0;
  text-transform: uppercase;
  color: ${props => props.theme.label.color};
`);

export const ItemTitle = withAnimationContext(styled(Text)
  .attrs({
    forwardedAs: 'h3',
    variant: 'titleSmall',
  })
  .withConfig({
    shouldForwardProp: prop =>
      !['forwardedAs', 'animationContext'].includes(prop),
  })`
  padding-top: ${cssSpacing('base')};
  margin-bottom: ${cssSpacing('tighter-2')};

  ${fadeInAnimation(DELAYS.FIFTH)};

  @media (min-width: ${screenSmMin}) {
    max-height: 3.6em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @media (max-width: ${screenSmMax}) {
    padding-top: ${cssSpacing('tighter-2')};
    margin-bottom: 0;
  }

  @media (min-width: ${screenXsMin}) and (max-width: ${screenSmMax}) {
    margin-top: ${cssSpacing('base')};
    margin-bottom: ${cssSpacing('tighter-2')};
  }
`);

export const ItemText = withAnimationContext(styled(Text)
  .attrs({
    forwardedAs: 'p',
    variant: 'bodyMedium',
  })
  .withConfig({
    shouldForwardProp: prop =>
      !['forwardedAs', 'animationContext'].includes(prop),
  })`
  ${fadeInAnimation(DELAYS.SIXTH)};
  /* no description text on mobile */
  @media (max-width: ${screenXxsMax}) {
    display: none;
  }
`);

export const StyledLink = styled(UnstyledLink)<any>`
  text-decoration: none;
  color: ${cssColorValue('textBase')};

  &:hover,
  &:focus:not(:disabled),
  &:active:not(:disabled) {
    text-decoration: none;
    color: ${cssColorValue('textSubdued')};
  }

  &:hover {
    img,
    svg {
      ${transformScaleUp};
      transition: transform 0.3s ease-in-out;
    }
    svg {
      opacity: 0.8;
    }
  }
`;

export const LoadMoreButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: ${cssSpacing('looser')};
`;

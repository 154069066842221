// ignore-string-externalization

import React from 'react';
import styled from 'styled-components';
import {
  cssSpacing,
  titleMedium,
  Text,
  titleSmall,
  IconPlay,
  screenXxsMax,
  screenXsMax,
  screenSmMax,
  screenMdMin,
  gray7,
  white,
} from '@spotify-internal/encore-web';
import { itemToSearchHit } from '../../Search';
import type { SearchHit } from '../../Search/types';
import { ModuleType } from '../types';

import { Link } from '../../ui';

import { ContentfulImage } from '../../Contentful/ContentfulImage';
import { hexToRgba } from '../../../../features/src/color-conversion';
import { ImageWrapper } from '../../ContentGrid/index.styles';
import { transformScaleUp } from '../../utils';
import { useLocale } from '@spotify-internal/i18n-core';
import { Grid } from '@spotify-internal/frodor-modules';

const RelatedStoriesList = styled(Grid)`
  background: ${(props: { themeName: string }) =>
    props.themeName === 'whiteOnBlack' ? gray7 : 'unset'};
  color: ${({ theme }) => theme.box.text};
  padding-top: ${cssSpacing('looser-4')};
  padding-bottom: ${cssSpacing('looser-4')};
  @media (max-width: ${screenXsMax}) {
    padding: ${cssSpacing('base')} ${cssSpacing('base')}
      ${cssSpacing('looser-4')};
  }
`;

const StyledSupertext = styled.div`
  grid-column: 1/-1;
  padding-bottom: ${cssSpacing('looser-4')};
  ${titleMedium}

  @media (max-width: ${screenXxsMax}) {
    padding: ${cssSpacing('base')} 0;
  }
`;

const ItemWrapper = styled(Link)`
  grid-column: 1/-1;
  display: flex;
  flex-direction: row;
  padding-bottom: ${cssSpacing('tighter-4')};

  :last-child {
    padding-bottom: 0;
  }

  @media (max-width: ${screenXxsMax}) {
    padding-bottom: ${cssSpacing('base')};
  }

  &:hover {
    h1 {
      color: ${props => hexToRgba(props.theme.link?.color || white, 0.75)};
    }
    img {
      ${transformScaleUp};
      transition: transform 0.3s ease-in-out;
    }
    svg {
      ${transformScaleUp};
      transition: transform 0.3s ease-in-out;
      opacity: 0.8;
    }
  }
`;

const Title = styled(Text).attrs({
  variant: 'titleMedium',
})`
  margin-bottom: ${cssSpacing('base')};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: ${screenSmMax}) {
    -webkit-line-clamp: 2;
  }
  @media (max-width: ${screenXxsMax}) {
    ${titleSmall}
  }
`;

const Description = styled(Text).attrs({
  variant: 'titleSmall',
})`
  max-height: ${cssSpacing('looser-4')};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 18px;

  @media (max-width: ${screenSmMax}) {
    display: none;
  }
`;

const ImageContainer = styled(ImageWrapper)`
  width: 20%;
  height: unset;
  overflow: hidden;

  @media (max-width: ${screenMdMin}) {
    width: 40%;
  }

  @media (max-width: ${screenXxsMax}) {
    width: 35%;

    svg {
        height: ${cssSpacing('base')};
        width: ${cssSpacing('base')};
        bottom: ${cssSpacing('tighter-4')};
        left: ${cssSpacing('tighter-4')};
      }
    }
  }
`;

const TextWrapper = styled.div`
  padding-left: ${cssSpacing('looser-4')};
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.box.text};

  @media (max-width: ${screenMdMin}) {
    padding-left: ${cssSpacing('base')};
    width: 55%;
  }

  @media (max-width: ${screenXxsMax}) {
    padding-left: ${cssSpacing('tighter-2')};
    width: 55%;
  }
`;

type RelatedStoriesListModuleType = ModuleType & {
  headline?: string;
  items?: SearchHit[];
};

const TRACK_CAT = 'module-relatedStoriesModule';

export const RelatedStoriesListModule: React.FC<
  RelatedStoriesListModuleType
> = ({ headline = '', items = [], themeName = 'whiteOnBlack' }) => {
  const locale = useLocale();
  const relatedStories = (
    items.map(item => itemToSearchHit(item, locale)) as NonNullable<SearchHit>[]
  ).filter(Boolean);

  return (
    (relatedStories?.length && (
      <RelatedStoriesList themeName={themeName}>
        <StyledSupertext>{headline}</StyledSupertext>
        {relatedStories.map((item, index) => (
          <ItemWrapper
            key={`link-${item.id}-${index}`}
            track={[TRACK_CAT, 'Related Stories']}
            href={item.url}
          >
            <ImageContainer
              video={item.contentType === 'videoPage'}
              featured={false}
            >
              {item.image && (
                <ContentfulImage asset={item.image}>
                  <IconPlay aria-hidden />
                </ContentfulImage>
              )}
            </ImageContainer>
            <TextWrapper key={`text-${item.id}-${index}`}>
              <Title>{item.title}</Title>
              <Description>{item.description}</Description>
            </TextWrapper>
          </ItemWrapper>
        ))}
      </RelatedStoriesList>
    )) || <></>
  );
};

import React from 'react';
import debounce from 'lodash/debounce';
import { v4 as uuidv4 } from 'uuid';
import {
  bodyMedium,
  cssSpacing,
  FormInput,
  FormInputIcon,
  IconEmail,
  IconSearch,
  marginal,
} from '@spotify-internal/encore-web';
import { useRouter } from 'next/router';
import { Box, Link } from '../ui';
import styled from 'styled-components';
import { searchLoader } from './searchLoader';
import type { SearchHit, SearchQuery, SearchResponse } from './types';
import { useTracking } from '../Tracking';
import { useLocale, useT } from '@spotify-internal/i18n-core';
import { info } from '../../../features/src/i18n/locales/info';

const SUGGEST_DEBOUNCE_TIME = 300;
const FOOTER_LINK_URL = '/contact';

type SearchBarProps = {
  query?: string; // set initial query
  contentTypes?: string[]; // restrict content types, can include boost e.g. faq^2
  placeholder?: string; // placeholder text
  search?: boolean; // allow search on enter - redirects to search page
  searchUrl?: string; // url to redirect to if search enabled
  suggest?: boolean; // show suggestions
  suggestCount?: number; // number of suggestions to show
  suggestInitial?: boolean; // show suggestions on no query
  suggester?: (query: SearchQuery) => Promise<SearchResponse>;
  getSuggestUrl?: (sug: SearchHit) => string;
  showFooter?: boolean; // show contact us footer
  trackCategory?: string; // category for event tracking
  forceLocale?: string; // force locale instead of default
};

const SearchBarWrapper = styled(Box).attrs({
  noPadding: true,
})`
  margin-bottom: ${cssSpacing('base')};
  background: transparent;

  input {
    ${bodyMedium}
  }

  svg {
    margin-left: ${cssSpacing('tighter-4')};
  }
`;

const SearchBox = styled(FormInput)`
  border-radius: ${cssSpacing('tighter-4')};
`;

const Suggestions = styled.ul<{ width: number }>`
  position: absolute;
  background: ${({ theme }) => theme.searchBar.background};
  padding-left: 0;
  padding-right: 0;
  border: 1px solid ${({ theme }) => theme.searchBar.border};
  border-top: 0;
  margin-left: ${cssSpacing('tighter-4')};
  z-index: 1000;
  width: ${({ width }) => `${width - 8}px`};
  max-height: 50vh;
  overflow: auto;
  text-align: left;
`;

const Suggestion = styled.li<{ selected?: boolean }>`
  margin: 0;
  padding-top: ${cssSpacing('tighter-2')};
  padding-bottom: ${cssSpacing('tighter-2')};
  padding-left: ${cssSpacing('base')};
  padding-right: ${cssSpacing('base')};
  ${bodyMedium}

  ${Link} {
    ${({ selected, theme }) => (selected ? `color: ${theme.link.hover}` : '')};
    &:hover {
      color: ${({ theme }) => theme.link.hover};
    }
  }
`;

const SuggestionHeader = styled.li`
  text-transform: uppercase;
  ${marginal}
  padding-top: ${cssSpacing('tighter')};
  padding-left: ${cssSpacing('tighter')};
  padding-right: ${cssSpacing('tighter')};
  color: ${({ theme }) => theme.searchBar.suggestionHeader};
`;

const SuggestionFooter = styled(Suggestion)`
  svg {
    margin-right: ${cssSpacing('tighter-2')};
    vertical-align: -3px;
    color: ${({ theme }) => theme.searchBar.icon};
  }
`;

export const SearchBar: React.FC<SearchBarProps> = ({
  query = '',
  contentTypes,
  placeholder = '',
  search = false,
  suggest = true,
  suggestCount = 10,
  suggestInitial = false,
  suggester = searchLoader.load.bind(searchLoader),
  getSuggestUrl = sug => sug?.url,
  searchUrl,
  trackCategory = 'Search Bar',
  showFooter = true,
  forceLocale,
}) => {
  const t = useT();
  const defaultLocale = useLocale();
  const router = useRouter();
  const locale =
    forceLocale || (router.query?.locale as string) || defaultLocale;
  const language = info[locale]?.contentful ?? info.en.contentful;
  const { sendEvent } = useTracking();
  const input: any = React.useRef(null);
  const getInput = () => input.current?.value?.trim();

  const debug =
    Boolean(router?.query?.debug_search) ||
    process.env.NODE_ENV === 'development';

  const [suggestions, setSuggestions] = React.useState<SearchHit[]>([]);
  const [showSuggestions, setShowSuggestions] = React.useState(suggestInitial);
  const [selectedSuggestion, setSelectedSuggestion] = React.useState(-1);
  const suggestUid = React.useRef('');

  // make refs array for suggestions
  const sugRefs = React.useRef<HTMLLIElement[]>([]);
  React.useEffect(() => {
    sugRefs.current = sugRefs.current.slice(
      0,
      suggestions.length + (showFooter ? 1 : 0),
    );
  }, [suggestions, sugRefs, showFooter]);

  const hideSuggestions = React.useCallback(() => {
    suggest && setShowSuggestions(false);
  }, [suggest, setShowSuggestions]);

  React.useEffect(() => {
    window.addEventListener('click', hideSuggestions);
    window.addEventListener('resize', hideSuggestions);
    const onKeyUp = (e: KeyboardEvent) =>
      suggest && e.key === 'Escape' && setShowSuggestions(false);
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('click', hideSuggestions);
      window.removeEventListener('resize', hideSuggestions);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [hideSuggestions, suggest]);

  const doSearch = React.useCallback(() => {
    const q = input.current.value;
    const url = `${searchUrl || router.pathname}?query=${encodeURIComponent(
      q,
    )}&locale=${language}`;
    router.push(url);
  }, [input, router, language, searchUrl]);

  const updateSuggestions = debounce(async () => {
    suggestUid.current = uuidv4();
    const searchQuery: SearchQuery = {
      uid: suggestUid.current,
      query: input.current.value,
      limit: suggestCount,
      suggest: true,
      locale: language,
      debug,
      contentTypes,
    };

    const resp = await suggester(searchQuery);

    if (debug) {
      // eslint-disable-next-line no-console
      console.log('Search Response', resp);
    }

    setShowSuggestions(true);
    if (resp.error) {
      setSuggestions([]);
      sendEvent({
        eventCategory: `Frodor - Search Suggestions - ${trackCategory}`,
        eventAction: 'error',
        eventLabel: `${suggestUid.current || '?'} - ${resp.error}`,
      });
    } else if (resp) {
      trackSuggestions(resp);
      setSuggestions(resp.hits.filter(Boolean));
    } else {
      setSuggestions([]);
    }
  }, SUGGEST_DEBOUNCE_TIME);

  const resetSearch = React.useCallback(() => {
    if (suggest) {
      setShowSuggestions(suggestInitial);
      setSelectedSuggestion(-1);
      input.current.value = '';
    }
  }, [
    suggestInitial,
    input,
    setSelectedSuggestion,
    setShowSuggestions,
    suggest,
  ]);

  // scroll current suggestion into view
  React.useEffect(() => {
    const el = sugRefs.current[selectedSuggestion];
    if (el) el.scrollIntoView(false);
  }, [selectedSuggestion]);

  const trackSuggestions = React.useCallback(
    (resp: SearchResponse) => {
      const hits = resp?.hits?.filter(Boolean);
      if (!hits?.length) {
        sendEvent({
          eventCategory: `Frodor - Search Suggestions - ${trackCategory}`,
          eventAction: 'view',
          eventLabel: `${suggestUid.current || '?'} - No Results`,
        });
      }

      hits.forEach((hit, idx) => {
        const position = `[${idx + 1}/${resp.hits.length}]`;
        sendEvent({
          eventCategory: `Frodor - Search Suggestions - ${trackCategory}`,
          eventAction: 'view',
          eventLabel: `${hit.searchUid} - ${
            resp.query || 'n/a'
          } - ${position} - ${hit.url}`,
        });
      });
    },
    [trackCategory],
  );

  return (
    <SearchBarWrapper>
      <FormInputIcon iconLeading={<IconSearch />}>
        <SearchBox
          type="text"
          aria-label={t('FRODOR_4bcf48', 'search bar', '')}
          placeholder={placeholder}
          ref={input}
          defaultValue={query || ''}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            setShowSuggestions(getInput() || suggestInitial);
          }}
          onKeyUp={(e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
              // navigate to selected suggestion
              if (
                suggest &&
                showSuggestions &&
                suggestions &&
                selectedSuggestion >= 0
              ) {
                const url =
                  selectedSuggestion === suggestions.length
                    ? FOOTER_LINK_URL
                    : suggestions[selectedSuggestion]?.url;
                if (url) {
                  router.push(url);
                  resetSearch();
                }
              } else if (search) {
                doSearch();
              }
            } else if (suggest) {
              if (e.key === 'Escape') {
                setShowSuggestions(false);
              } else if (showSuggestions && e.key === 'ArrowDown') {
                e.stopPropagation();
                setSelectedSuggestion(i =>
                  i >= suggestions.length ? 0 : i + 1,
                );
              } else if (showSuggestions && e.key === 'ArrowUp') {
                setSelectedSuggestion(i =>
                  i <= 0 ? suggestions.length : i - 1,
                );
              } else if (e.key !== 'Escape') {
                updateSuggestions();
              }
            }
          }}
        />
      </FormInputIcon>

      {suggest && showSuggestions && (getInput() || suggestInitial) && (
        <Suggestions
          width={input.current?.clientWidth || 300}
          data-testid="search-suggestions"
        >
          <SuggestionHeader>Results ({suggestions.length})</SuggestionHeader>

          {suggestions.map((sug, idx) => (
            <Suggestion
              key={sug.id}
              selected={selectedSuggestion === idx}
              ref={el => {
                sugRefs.current[idx] = el!;
              }}
            >
              <Link
                track={[
                  `Search Suggestion - ${trackCategory} - ${
                    sug.searchUid || '?'
                  } - ${getInput()}`,
                  sug.url,
                ]}
                href={`${getSuggestUrl(sug)}&q=${getInput()}`}
              >
                {sug.title}
              </Link>
              {debug && sug.score && ` [${sug.score.toFixed(2)}]`}
            </Suggestion>
          ))}

          {showFooter && (
            <>
              <SuggestionHeader>
                {t(
                  'FRODOR_SEARCH_BAR_CANT_FIND',
                  "Can't find what you're looking for?",
                  'To get help contact us using the button nearby',
                )}
              </SuggestionHeader>
              <SuggestionFooter
                selected={selectedSuggestion === suggestions.length}
                ref={el => {
                  sugRefs.current[suggestions.length] = el!;
                }}
              >
                <Link
                  href={`${FOOTER_LINK_URL}?q=${getInput()}`}
                  track={[
                    trackCategory,
                    `Contact Us - ${suggestUid.current || '?'} - ${getInput()}`,
                  ]}
                >
                  <IconEmail iconSize={16} aria-hidden />
                  {t('FRODOR_8dcde8', 'Contact us', '')}
                </Link>
              </SuggestionFooter>
            </>
          )}
        </Suggestions>
      )}
    </SearchBarWrapper>
  );
};

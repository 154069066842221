import React, { MutableRefObject, useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { useSwipeable } from 'react-swipeable';
import {
  IconChevronLeft,
  IconChevronRight,
  KeyboardDetectionContext,
} from '@spotify-internal/encore-web';
import { useTracking } from '../../Tracking';
import { MODULE_THEMES, defaultTheme } from '../../Theme';
import { getTallest, setTallestClass } from '../../utils';
import { ModuleType, ModuleCarouselType, ModuleContentType } from '../types';

import {
  Carousel,
  Slides,
  Slide,
  Dots,
  Dot,
  NextArrow,
  PreviousArrow,
  Splash,
} from './ModuleCarousel.styles';
import { useT, useLocale } from '@spotify-internal/i18n-core';

export const CAROUSEL_AUTO_ROTATE_MS = 7000;

export const TRANSLATED_MODULE_TYPES: Set<ModuleContentType> = new Set([
  'headerModule',
] as ModuleContentType[]);

export const ModuleCarousel = React.forwardRef<
  React.MutableRefObject<ModuleType>,
  ModuleCarouselType
>(
  (
    {
      carouselSlides = [],
      onShowSlide = () => {},
      showDots = true,
      doAutoRotate = false,
      isHeader,
      isVisible = false,
      contentType = 'moduleCarousel',
      title = 'Unknown',
      startIndex = 0,
      Module,
    },
    _,
  ) => {
    const t = useT();
    const locale = useLocale();
    const { sendEvent } = useTracking();
    const { isUsingKeyboard } = useContext(KeyboardDetectionContext);
    const [activeIndex, setActiveIndex] = React.useState<number | Function>(
      startIndex,
    );
    const curIndex = activeIndex as number;
    const autoRotate = React.useRef(doAutoRotate);
    const autoRotateInterval = React.useRef<NodeJS.Timeout | null>(null);
    const slides = carouselSlides.filter(s =>
      Boolean(
        s &&
          s.contentType &&
          (!TRANSLATED_MODULE_TYPES.has(s.contentType) ||
            (s.languages !== undefined && s.languages.includes(locale))),
      ),
    );

    const viewed = React.useRef<{ [idx: number]: boolean }>({});
    const [tallest, setTallest] = React.useState<number>(1);
    const [width, setWidth] = React.useState<number>(1);

    function jumpToSlide(index: number | Function) {
      setActiveIndex(index);
      stopAutoRotate();
    }

    const nextIndex = (i: number) => (i + 1 >= slides.length ? 0 : i + 1);
    const prevIndex = (i: number) => (i - 1 >= 0 ? i - 1 : slides.length - 1);

    function startAutoRotate() {
      autoRotate.current = true;

      if (!autoRotateInterval.current) {
        autoRotateInterval.current = setInterval(() => {
          setActiveIndex(nextIndex);
        }, CAROUSEL_AUTO_ROTATE_MS);
      }
    }

    function stopAutoRotate() {
      autoRotate.current = false;
      if (autoRotateInterval.current) {
        clearInterval(autoRotateInterval.current);
        autoRotateInterval.current = null;
      }
    }

    const fireEvent = React.useCallback(
      (action: string, label: string = title) => {
        sendEvent({
          eventCategory: `Frodor - module-${contentType}`,
          eventAction: action,
          eventLabel: label,
        });
      },
      [title],
    );

    const swipeHandlers = useSwipeable({
      onSwipedLeft: () => {
        fireEvent('swipe');
        jumpToSlide(nextIndex);
      },
      onSwipedRight: () => {
        fireEvent('swipe');
        jumpToSlide(prevIndex);
      },
      preventDefaultTouchmoveEvent: true,
      trackMouse: false,
      trackTouch: true,
    });

    React.useEffect(() => {
      if (doAutoRotate && isVisible) {
        startAutoRotate();
      } else {
        stopAutoRotate();
      }
      return () => {
        stopAutoRotate();
      };
    }, [doAutoRotate, isVisible]);

    React.useEffect(() => {
      const curSlide = slides[curIndex];
      if (!viewed.current[curIndex]) {
        // fire event if slide not viewed before
        viewed.current[curIndex] = true;
        fireEvent('view-slide', `${title} - ${curSlide?.title || 'Unknown'}`);
      }
      onShowSlide?.(curSlide, curIndex);
    }, [activeIndex]);

    React.useEffect(() => {
      onShowSlide?.(slides[curIndex], curIndex);
    }, [activeIndex]);

    const moduleRefs: Array<React.MutableRefObject<any>> = [];

    const getModuleWithRef = (mod: ModuleType, index: number) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      moduleRefs[index] = React.useRef<MutableRefObject<ModuleType>>();
      return <Module {...mod} ref={moduleRefs[index]} />;
    };

    React.useEffect(() => {
      const handleResize = () => {
        setWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return function cleanupListener() {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    React.useEffect(() => {
      setTallest(getTallest(moduleRefs));
      setTallestClass(moduleRefs);
    }, [getTallest(moduleRefs), width]);

    return (
      <>
        <Splash tallest={tallest} />
        <Carousel isHeader={isHeader} {...swipeHandlers} tallest={tallest}>
          <Slides>
            {slides.map((mod, index) => (
              <ThemeProvider
                key={`module-carousel-slide-${index}`}
                theme={{
                  ...defaultTheme,
                  ...MODULE_THEMES[mod.themeName || ''],
                }}
              >
                <Slide
                  active={curIndex === index}
                  data-testid="slide"
                  backgroundColor={
                    mod.backgroundColor || defaultTheme.box.background
                  }
                >
                  {getModuleWithRef(mod, index)}
                </Slide>
              </ThemeProvider>
            ))}
          </Slides>

          {showDots && (
            <Dots>
              {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
              {slides.map((_, index) => (
                <Dot
                  key={`dot-${index}`}
                  active={curIndex === index}
                  onClick={() => {
                    fireEvent('click', 'Dots');
                    jumpToSlide(index);
                  }}
                  color={slides[curIndex].backgroundColor}
                  aria-label={`${t(
                    'FRODOR_GO_TO_SLIDE_NUM_MODULE_CAROUSEL',
                    'Go to slide number {slideNum}',
                    'Slide in this case means a slide in a slideshow',
                    { slideNum: index + 1 },
                  )}`}
                />
              ))}
            </Dots>
          )}

          <PreviousArrow
            onClick={() => {
              fireEvent('click', 'Arrow');
              jumpToSlide(prevIndex);
            }}
            color={slides[curIndex]?.backgroundColor}
            aria-label={t(
              'FRODOR_GO_TO_PREV_SLIDE_MODULE_CAROUSEL',
              'Go to previous slide',
              'Slide in this case means a slide in a slideshow',
            )}
            iconOnly={IconChevronLeft}
            buttonSize="lg"
            isUsingKeyboard={isUsingKeyboard}
          />

          <NextArrow
            onClick={() => {
              fireEvent('click', 'Arrow');
              jumpToSlide(nextIndex);
            }}
            color={slides[curIndex]?.backgroundColor}
            aria-label={t(
              'FRODOR_GO_TO_NEXT_SLIDE_MODULE_CAROUSEL',
              'Go to next slide',
              'Slide in this case means a slide in a slideshow',
            )}
            iconOnly={IconChevronRight}
            buttonSize="lg"
            isUsingKeyboard={isUsingKeyboard}
          />
        </Carousel>
      </>
    );
  },
);

// ignore-string-externalization

import React, { MutableRefObject, Ref } from 'react';
import styled from 'styled-components';
import {
  Text,
  screenXsMax,
  screenLgMin,
  IconArrowRight,
  cssSpacing,
  bodyMediumBold,
  screenMdMax,
  titleLarge,
} from '@spotify-internal/encore-web';
import {
  DELAYS,
  fadeInAnimation,
  withAnimationContext,
  Grid,
  TWO_COLUMN_MAX_WIDTH,
  AnimationContextType,
} from '@spotify-internal/frodor-modules';
import { hexToRgba } from '../../../../features/src/color-conversion';
import { ModuleType } from '../types';
import { ContentfulImage } from '../../Contentful/ContentfulImage';
import { ContentfulAsset } from '../../Contentful';
import { ImageAspectBox1x1, ButtonTertiaryAsLink, Link } from '../../ui';
import { formatText, fixHref, withoutProps } from '../../utils';
import { Colors } from '../../ui/colorsType';

const StyledSupertext = styled(Text).attrs({
  variant: 'bodyMedium',
})`
  padding-bottom: ${cssSpacing('base')};
  text-transform: uppercase;
`;

const Header = styled(Grid)`
  background-color: ${(props: Colors) => props.backgroundColor};
  color: ${(props: Colors) => props.textColor};
  padding-top: 104px;
  padding-bottom: ${cssSpacing('looser')};

  &.tallest {
    height: 100%;
  }

  @media (min-width: ${screenLgMin}) {
    padding-bottom: ${cssSpacing('looser-4')};
  }

  @media (max-width: ${TWO_COLUMN_MAX_WIDTH}) {
    padding-top: ${cssSpacing('looser-4')};
  }
  @media (max-width: ${screenXsMax}) {
    padding-top: ${cssSpacing('looser-6')};
  }
`;

const ImageContainer = styled(ImageAspectBox1x1)`
  grid-column: 8 / 13;
  @media (max-width: ${TWO_COLUMN_MAX_WIDTH}) {
    grid-column: 1 / -1;
    order: 1;
  }
`;

const InnerText = styled.div`
  grid-column: 1 / 7;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1080px) {
    grid-column: 1 / 7;
  }
  @media (max-width: ${TWO_COLUMN_MAX_WIDTH}) {
    padding-top: ${cssSpacing('looser-4')};
    grid-column: 1 / -1;
    order: 2;
    padding-bottom: ${cssSpacing('tighter-4')};
  }
`;

// TODO Remove hardcoding of font-size - there is no 56 in Encore, it is Frodor specific
const Headline = withAnimationContext(styled(Text).attrs({
  variant: 'headlineMedium',
})<{ textColor: string; animationContext: AnimationContextType }>`
  ${fadeInAnimation(DELAYS.SECOND)};

  max-width: 684px;
  display: block;
  font-size: 72px;
  @media (max-width: ${screenMdMax}) {
    ${titleLarge}
  }
`);

const Subtext = withAnimationContext(styled(Text).attrs({
  variant: 'titleSmall',
})`
  ${fadeInAnimation(DELAYS.THIRD)};
  padding-top: ${cssSpacing('looser')};
  @media (max-width: ${screenMdMax}) {
    ${bodyMediumBold}
  }
`);

const StyledLink = styled(withoutProps(Link, 'textColor'))<{
  textColor: string;
}>`
  color: ${({ textColor }) => textColor};

  &&:hover:not(:disabled),
  &&:focus:not(:disabled) {
    color: ${(props: Colors) => hexToRgba(props.textColor || '', 0.75)};
  }
`;

const FeaturedContentLink = withAnimationContext(styled(
  withoutProps(ButtonTertiaryAsLink, 'textColor'),
)<{
  textColor: string;
  animationContext: AnimationContextType;
}>`
  ${fadeInAnimation(DELAYS.FOURTH)};
  color: ${({ textColor }) => textColor};
  align-self: flex-start;
  &&:hover:not(:disabled),
  &&:focus:not(:disabled) {
    color: ${({ textColor }) => hexToRgba(textColor || '', 0.75)};
  }
  padding-top: ${cssSpacing('looser-2')};

  margin-bottom: ${cssSpacing('tighter-4')};
  @media (max-width: ${screenXsMax}) {
    margin-bottom: 0px;
  }

  svg {
    [dir='rtl'] & {
      transform: scaleX(-1);
    }
  }
`);

const getHeadline = (
  ctaText: string,
  ctaLink: string,
  headline: string,
  textColor: string,
  trackCat: string,
) => {
  if (headline) {
    const HeadlineElement = (
      <Headline
        textColor={textColor}
        data-testid="header-module-headline"
        dangerouslySetInnerHTML={{ __html: formatText(headline) }}
      />
    );
    if (ctaText && ctaLink) {
      return (
        <StyledLink
          textColor={textColor}
          href={fixHref(ctaLink)}
          track={[trackCat, `CTA Link - ${ctaText}`]}
        >
          {HeadlineElement}
        </StyledLink>
      );
    }
    return HeadlineElement;
  }
  return <></>;
};

export type HeaderModuleType = ModuleType & {
  headline: string;
  subtext?: string;
  supertext?: string;
  image: ContentfulAsset;
  ctaLink?: string;
  ctaText?: string;
  backgroundColor: string;
  textColor: string;
};

const TRACK_CAT = 'module-heroHeader';

export const HeaderModule = React.forwardRef<
  React.MutableRefObject<ModuleType>,
  HeaderModuleType
>(
  (
    {
      headline = '',
      subtext = '',
      supertext = '',
      image,
      ctaLink = '',
      ctaText = '',
      backgroundColor = '',
      textColor = '',
    },
    ref,
  ) => {
    return (
      <Header
        backgroundColor={backgroundColor}
        textColor={textColor}
        ref={ref as Ref<MutableRefObject<ModuleType>>}
      >
        <InnerText>
          {supertext && <StyledSupertext>{supertext}</StyledSupertext>}
          {getHeadline(ctaText, ctaLink, headline, textColor, TRACK_CAT)}
          {subtext && (
            <Subtext
              dangerouslySetInnerHTML={{ __html: formatText(subtext) }}
            />
          )}
          {ctaText && ctaLink && (
            <FeaturedContentLink
              textColor={textColor}
              href={fixHref(ctaLink)}
              track={[TRACK_CAT, `CTA Link - ${ctaText}`]}
              iconTrailing={() => <IconArrowRight iconSize={16} />}
              condensedAll
            >
              {ctaText}
            </FeaturedContentLink>
          )}
        </InnerText>
        <ImageContainer>
          {image && <ContentfulImage asset={image} />}
        </ImageContainer>
      </Header>
    );
  },
);

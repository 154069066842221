import { CSSProperties } from 'react';
import { TextProps } from '@spotify-internal/encore-web';

export type AdjustedVariantResult = {
  variant: TextProps['variant'];
  paddingBottom?: CSSProperties['paddingBottom'];
  fontSize?: CSSProperties['fontSize'];
};

export const adjustVariant = (variant: any): AdjustedVariantResult => {
  switch (variant) {
    case 'heading1':
      return { variant: 'titleLarge', paddingBottom: '0.67em' };
    case 'heading2':
      return { variant: 'titleMedium', paddingBottom: '0.75em' };
    case 'heading3':
      return { variant: 'titleSmall' };
    case 'heading4':
      return { variant: 'titleSmall', fontSize: '18px' };
    default:
      return { variant };
  }
};

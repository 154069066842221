// ignore-string-externalization

import { createLoader } from '@spotify-internal/creator-data-loading';
import { fetchEntry, fetchEntryList } from '../Contentful';
import { TemplateType } from '.';

type TemplateLoaderProps = {
  contentType: string;
  urlSlug: string;
  smartlingLanguage: string;
  preview?: boolean;
};

export const templateLoader = createLoader<
  TemplateLoaderProps,
  TemplateType | null
>(
  async ({ contentType, urlSlug, smartlingLanguage, preview = false }) => {
    return await fetchEntry<TemplateType>({
      contentType,
      filters: { urlSlug },
      locale: smartlingLanguage,
      preview,
    });
  },
  {
    cache: false,
  },
);

export const templateUrlsLoader = createLoader<
  Omit<TemplateLoaderProps, 'urlSlug'>,
  string[]
>(
  async ({ contentType, preview = false }) => {
    const resp = await fetchEntryList<TemplateType>({
      contentType,
      limit: 1000,
      select: ['fields.urlSlug'],
      preview,
    });
    return resp.entries.map(e => e.urlSlug).filter(Boolean) as NonNullable<
      string[]
    >;
  },
  {
    cache: false,
  },
);

import {
  ButtonPrimaryProps,
  LoadingIndicator,
  ButtonPrimary,
} from '@spotify-internal/encore-web';
import React from 'react';

export type LoadingButtonProps = {
  loading?: boolean;
} & ButtonPrimaryProps;

/**
 * When passed a loading prop, this button will not show up.
 * Pass props to it just like any other Button
 */
export const LoadingButton: React.FC<LoadingButtonProps> = ({
  loading,
  ...rest
}) => {
  if (loading) {
    return <LoadingIndicator />;
  }
  return <ButtonPrimary {...rest} />;
};

import React from 'react';
import styled from 'styled-components';
import {
  ContentfulAsset,
  JumpLinkHeroModule as FMJumpLinkHeroModule,
} from '@spotify-internal/frodor-modules';
import { ModuleType } from './types';
import { Colors } from '../ui/colorsType';
import { cssColorValue } from '@spotify-internal/encore-web';

const JLWrapper = styled.div<{
  backgroundColor?: string;
  textColor?: string;
}>`
  // override spbootstrap css
  // it is setting border-box and that messes with jumplink/headline height detection
  .jl,
  .jl-headline {
    box-sizing: unset !important;
  }
`;

type JumpLinkType = ModuleType & {
  headline: string;
  title: string;
  svgIcon?: string;
  colors: any;
  hoverBackground: string;
  eyebrowText?: string;
  supertext?: string;
};

export type JumpLinkHeroModuleType = ModuleType & {
  headline: string;
  isTagline: boolean;
  tagline?: string;
  paragraph?: string;
  image?: ContentfulAsset;
  colors?: Colors;
  layout?: string;
  jumpLinks: JumpLinkType[];
  eyebrowOrHeadline: boolean;
  eyebrowText?: string;
  supertext?: string;
  useNumberedJumpLinks: boolean;
};

export const JumpLinkHeroModule = React.forwardRef<
  React.MutableRefObject<ModuleType>,
  JumpLinkHeroModuleType
>((props, ref) => {
  return (
    <JLWrapper
      backgroundColor={
        props.colors?.backgroundColor || cssColorValue('backgroundBase')
      }
    >
      <FMJumpLinkHeroModule {...props} ref={ref} pauseHiddenVideo />
    </JLWrapper>
  );
});

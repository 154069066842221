// ignore-string-externalization

import { TemplateType } from '.';
import { ContentfulAsset, makeContentfulAsset } from '../Contentful';
import { HeaderModuleType } from '../Module/HeroHeaderModule';
import { VideoModuleType } from '@spotify-internal/frodor-modules';
import { youtubeIdRegex } from '../utils';
import { ArticleTemplateType } from './ArticleTemplate';

export function youtubeThumbnailUrl(rawVideoUrl: string | undefined) {
  const videoUrl = rawVideoUrl?.split('#')[0]?.trim();
  if (!videoUrl) {
    return undefined;
  }

  const videoId = youtubeIdRegex.exec(videoUrl);
  return videoId && videoId[1]
    ? `https://img.youtube.com/vi/${videoId[1]}/maxresdefault.jpg`
    : undefined;
}

export const socialCardImageHandlers = {
  landingPage: (template: TemplateType) => {
    const { socialCard } = template;

    if (!socialCard) {
      if ('heroModule' in template) {
        const hm = (template as any).heroModule!;
        if (hm) {
          return (
            hm.image || ('carouselSlides' in hm && hm.carouselSlides[0]?.image)
          );
        }
      }
    }

    return socialCard;
  },

  articlePage: (template: TemplateType) => {
    const headerModule = (template as ArticleTemplateType)
      ?.headerModule as HeaderModuleType;
    return headerModule?.image;
  },

  videoPage: (template: TemplateType) => {
    const thumbnailImage = (template as ArticleTemplateType).thumbnailImage;
    if (thumbnailImage) {
      return thumbnailImage;
    }

    const video = (template as ArticleTemplateType)?.video as VideoModuleType;
    const url = youtubeThumbnailUrl(video?.videoUrl);
    return (
      url &&
      makeContentfulAsset({
        url,
        contentType: 'image/jpeg',
        width: 1280,
        height: 720,
        description: video?.title,
      })
    );
  },

  helpHomePage: getHelpSocialCard,

  typeformPage: () => null,
};

export function getTemplateSocialCardAsset(
  template: TemplateType,
): ContentfulAsset | undefined {
  const findSocialCardImage = socialCardImageHandlers[template.contentType];
  return findSocialCardImage ? findSocialCardImage(template) : undefined;
}

export function getTemplateSocialCardUrl(template: TemplateType): string {
  const asset = getTemplateSocialCardAsset(template);

  return (
    typeof asset === 'object'
      ? asset?.file?.contentType?.startsWith('image/') && asset?.file?.url
      : asset
  ) as string;
}

function getHelpSocialCard(): ContentfulAsset {
  const url = '/S4ALogo.jpg';
  return makeContentfulAsset({
    url,
    contentType: 'image/jpeg',
    width: 1280,
    height: 720,
    description: 'Spotify for Artists - Help',
  });
}

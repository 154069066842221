// ignore-string-externalization

import React from 'react';
import styled from 'styled-components';
import {
  screenSmMax,
  screenMdMin,
  makeColorSetClass,
  cssColorValue,
  cssSpacing,
} from '@spotify-internal/encore-web';
import { ModuleType } from '../types';
import { ContentfulAsset } from '../../Contentful';
import { FeaturedContentHeadline } from '../../ui';
import { ThreeUpSegment } from './ThreeUpSegment';
import { MODULE_COLOR_SETS } from '../../Theme';
import { Grid } from '@spotify-internal/frodor-modules';

const Wrapper = styled(Grid)`
  background-color: ${cssColorValue('backgroundBase')};

  padding-top: ${cssSpacing('looser')};
  padding-bottom: ${cssSpacing('looser')};
`;

const ThreeUp = styled.div`
  grid-column: 1 /-1;
  display: flex;
  flex-direction: column;
  padding-bottom: ${cssSpacing('looser')};

  & h1,
  h2 {
    padding-top: ${cssSpacing('base')};
    padding-bottom: ${cssSpacing('tighter-2')};
  }

  @media (min-width: ${screenMdMin}) {
    & h1 {
      padding-bottom: ${cssSpacing('looser-4')};
    }
  }

  @media (max-width: ${screenSmMax}) {
    padding-bottom: ${cssSpacing('tighter-2')};

    [data-aspect-ratio='21x9'] {
      order: 1;
    }
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${screenSmMax}) {
    flex-direction: column;
    gap: ${cssSpacing('looser-4')};
  }
`;

const SegmentWrapper = styled.div`
  width: 25%;

  @media (max-width: ${screenSmMax}) {
    width: 100%;
  }
`;

type ThreeUpModuleType = ModuleType & {
  headline?: string;
  image?: ContentfulAsset;
  caption?: string;
  supertext?: string;
  segments?: {
    headline?: string;
    body?: string;
    image?: ContentfulAsset;
  }[];
};

export const ThreeUpModule = React.forwardRef<
  React.MutableRefObject<ModuleType>,
  ThreeUpModuleType
>(
  (
    {
      headline = '',
      supertext = '',
      image,
      caption = '',
      segments = [],
      themeName,
    },
    ref,
  ) => {
    const fchProps = {
      headline,
      supertext,
      image,
      useFeaturedContentHeadline: true,
      caption,
    };
    return (
      <Wrapper
        className={makeColorSetClass(
          MODULE_COLOR_SETS[themeName || 'whiteOnBlack'],
        )}
      >
        <ThreeUp ref={ref as React.RefObject<HTMLDivElement>}>
          <FeaturedContentHeadline {...fchProps} />
          <Bottom>
            {segments.map(segment => (
              <SegmentWrapper key={segment.headline}>
                <ThreeUpSegment threeUpSegment={segment} />
              </SegmentWrapper>
            ))}
          </Bottom>
        </ThreeUp>
      </Wrapper>
    );
  },
);

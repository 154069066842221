import React, { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { TEMPLATE_THEMES } from '../Theme';
import { PageHead } from '../Layout';
import { ContentfulAsset } from '../Contentful';
import { LandingPageTemplate } from './LandingPageTemplate';
import { ArticleTemplate } from './ArticleTemplate';
import { TypeformTemplate } from './TypeformTemplate';
import { getTemplateSocialCardUrl } from './socialCard';

export { templateLoader, templateUrlsLoader } from './useTemplate';
export { LandingPageTemplate } from './LandingPageTemplate';

export type TemplateContentType =
  | 'landingPage'
  | 'videoPage'
  | 'articlePage'
  | 'helpHomePage'
  | 'typeformPage';

// base fields supported by all template types
export type TemplateType = {
  contentType: TemplateContentType;
  id: string;
  title?: string;
  description?: string;
  metadataTitle?: string;
  metadataDescription?: string;
  urlSlug?: string;
  theme?: string;
  requiredFeatureFlag?: string;
  socialCard?: ContentfulAsset | string;
  canonicalUrl?: string;
  releaseDate?: Date;
  introduction?: string;
  article?: string;
  preview?: boolean;
  error?: string;
};

type TemplateParams = TemplateType & {
  children?: ReactNode;
};

export const TEMPLATE_COMPONENTS: { [key: string]: React.FC<TemplateType> } = {
  landingPage: LandingPageTemplate,
  videoPage: ArticleTemplate,
  articlePage: ArticleTemplate,
  typeformPage: TypeformTemplate,
};

export const Template: React.FC<TemplateParams> = ({
  children,
  ...template
}) => {
  const {
    contentType,
    title = '',
    description = '',
    metadataTitle,
    metadataDescription,
    theme = 'Dark',
    canonicalUrl,
  } = template;

  const TemplateComponent = TEMPLATE_COMPONENTS[contentType];

  if (!TemplateComponent) {
    return <span>Template type "{contentType}" not found</span>;
  }

  const tplTheme = TEMPLATE_THEMES[theme];

  return (
    <ThemeProvider theme={tplTheme}>
      <PageHead
        title={metadataTitle || title}
        description={metadataDescription || description}
        image={getTemplateSocialCardUrl(template)}
        canonicalUrl={canonicalUrl}
      />
      <TemplateComponent {...template}>{children}</TemplateComponent>
    </ThemeProvider>
  );
};

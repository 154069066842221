// ignore-string-externalization

import React from 'react';
import styled from 'styled-components';
import {
  screenXxsMax,
  screenXsMin,
  screenXsMax,
  screenSmMin,
  screenSmMax,
  screenMdMin,
  Text,
  cssColorValue,
  cssSpacing,
} from '@spotify-internal/encore-web';

import { HeadlineWithSupertextComponent, ImageAspectBox16x9 } from '../../ui';
import { ShareButtonsComponent as ShareButtons } from '../../ui/ShareButtons';
import { ContentfulAsset } from '../../Contentful';
import { ContentfulImage } from '../../Contentful/ContentfulImage';

const maxWidthOfImage = '992px';
const maxWidthOfText = '664px';

const darkHeaderStyles = `
  @media (max-width: ${screenXsMax}) {
    padding-bottom: ${cssSpacing('looser-4')};
    margin-bottom: ${cssSpacing('looser-4')};
  }

  @media (min-width: ${screenSmMin}) {
    padding-bottom: ${cssSpacing('looser-4')};
    margin-bottom: ${cssSpacing('looser-4')};
  }
`;

const lightHeaderStyles = `
  margin-bottom: 0;
`;

const ArticleHeader = styled.div<{
  backgroundColor: string;
  textColor: string;
  nonBaseBackgroundColor: boolean;
}>`
  color: ${({ textColor }) => textColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding-top: ${cssSpacing('looser-4')};

  ${({ nonBaseBackgroundColor }) =>
    nonBaseBackgroundColor ? darkHeaderStyles : lightHeaderStyles}
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${screenXxsMax}) {
    padding-top: 36px;
    margin-left: ${cssSpacing('base')};
    margin-right: ${cssSpacing('base')};
  }

  @media (min-width: ${screenXsMin}) and (max-width: ${screenSmMax}) {
    padding-top: 60px;
    margin-left: 52px;
    margin-right: 52px;
  }

  @media (min-width: ${screenSmMin}) and (max-width: ${screenSmMax}) {
    padding-top: 76px;
    width: ${maxWidthOfText};
    margin: auto;
  }

  @media (min-width: ${screenMdMin}) {
    padding-top: 76px;
    max-width: ${maxWidthOfImage};
    margin: auto;
  }
`;

const ImageContainer = styled(ImageAspectBox16x9)`
  @media (min-width: ${screenMdMin}) {
    margin-left: ${cssSpacing('looser-4')};
    margin-right: ${cssSpacing('looser-4')};
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${cssSpacing('tighter-4')};
  align-items: center;

  a:last-child {
    padding-right: 0;
  }

  .addthis_toolbox {
    padding-bottom: 0;
    font-size: 0;
  }

  @media (max-width: ${screenXsMax}) {
    justify-content: space-between;
  }

  @media (min-width: ${screenMdMin}) {
    width: ${maxWidthOfText};
    margin: auto;
  }
`;

const AuthorDate = styled(Text).attrs({
  variant: 'bodyMediumBold',
})`
  padding-bottom: ${cssSpacing('base')};
`;

const TextContainer = styled.div`
  & div > p {
    padding-top: ${cssSpacing('base')};
  }

  & div > h1 {
    padding-top: ${cssSpacing('base')};
    text-align: center;
    font-size: 2.5rem;
    line-height: 2.75rem;
  }

  @media (min-width: ${screenMdMin}) {
    width: ${maxWidthOfText};
    margin: auto;

    div > h1 {
      font-size: 3rem;
      line-height: 3.25rem;
    }
  }
`;

type ArticleHeaderModuleType = {
  textColor?: string;
  backgroundColor?: string;
  headline?: string;
  supertext?: string;
  image: ContentfulAsset;
  authorDateString?: string;
};

export const ArticleHeaderModule: React.FC<ArticleHeaderModuleType> = ({
  headline = '',
  supertext = '',
  image,
  authorDateString = '',
  backgroundColor = '',
  textColor = '',
}) => {
  // spacing differs based on whether header background matches base background
  const nonBaseBackgroundColor: boolean = !cssColorValue(
    'backgroundBase',
  ).includes(backgroundColor.toLowerCase());

  return (
    <ArticleHeader
      backgroundColor={backgroundColor}
      textColor={textColor}
      nonBaseBackgroundColor={nonBaseBackgroundColor}
    >
      <ContentWrapper>
        <TextContainer>
          <HeadlineWithSupertextComponent
            centered
            headline={headline}
            supertext={supertext}
            text={textColor}
            background={backgroundColor}
          />
        </TextContainer>
        {image && (
          <ImageContainer>
            <ContentfulImage asset={image} />
          </ImageContainer>
        )}
        <Bottom>
          <AuthorDate>{authorDateString}</AuthorDate>
          <ShareButtons textColor={textColor} />
        </Bottom>
      </ContentWrapper>
    </ArticleHeader>
  );
};

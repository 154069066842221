import { ContentfulAsset } from '../Contentful';
import { fixHref, formatText, youtubeIdRegex } from '../utils';
import type { SearchHit } from './types';
import { getTemplateSocialCardAsset } from '../Template/socialCard';
import { getContentfulLocale } from '../../../features/src/i18n/locales/index';

// take an item downloaded from the CMS and convert it to a search hit
// returns null if the content is invalid

// the languages field is a string listing all locales offered
// separated by comma
function itemTranslatedForLanguage(item: any, locale: string) {
  return item.languages?.split(',').indexOf(getContentfulLocale(locale)) >= 0;
}

// items are either marked with a translated field
// or marked with a languages field
// or are the new customGridContent which will only be passed if translated
function isItemValidForLocale(item: any, locale: string) {
  return (
    item.contentType === 'customGridContent' ||
    item.translated === true ||
    itemTranslatedForLanguage(item, locale)
  );
}

export function itemToSearchHit(
  item: any,
  locale: string = 'en',
): SearchHit | null {
  if (item.isSearchHit) {
    return item;
  }

  if (locale !== 'en' && !isItemValidForLocale(item, locale)) {
    return null;
  }

  const ctype = item.contentType as string;
  if (
    !ctype ||
    !item.id ||
    !(item.urlSlug || item.url) ||
    Date.parse(item.releaseDate) > Date.now()
  ) {
    return null;
  }

  const doc = {
    contentType: ctype,
    id: item.id,
    urlSlug: item.urlSlug,
    isSearchHit: true,
  } as SearchHit;

  const videoUrlParsed = youtubeIdRegex.exec(item.videoUrl?.trim());
  const blogVideoUrlParsed = youtubeIdRegex.exec(item.featuredVideo?.trim());

  switch (ctype) {
    case 'event':
      doc.title = item.title?.trim();
      doc.description = formatText(item.description?.trim());
      doc.image = item.image; // TODO: fallback image based on franchise
      doc.date = item.date;
      doc.label = item.franchise;
      doc.location = item.location;
      doc.linkExternal = true;
      break;
    case 'blogPost':
      doc.title = item.title?.trim();
      doc.description = item.introduction?.trim();
      doc.image = item.featuredImage as ContentfulAsset | undefined;
      doc.videoUrl = (blogVideoUrlParsed && blogVideoUrlParsed[1]) || undefined;
      doc.label = item.label?.trim();
      doc.date = item.releaseDate;
      break;
    case 'video':
      doc.title = item.name?.trim();
      doc.description = item.body?.trim() || item.description?.trim();
      doc.duration = item.videoDuration?.trim();
      doc.videoUrl = (videoUrlParsed && videoUrlParsed[1]) || undefined;
      doc.image = item.thumbnailImage;
      doc.label = item.label?.trim();
      doc.date = item.releaseDate;
      break;
    case 'faq':
      doc.title = item.question?.trim();
      doc.label =
        item.category?.name?.trim() ||
        item.categories?.[0]?.name?.trim() ||
        undefined;
      break;
    case 'guideSection':
    case 'guidePage':
      doc.title = item.title?.trim();
      doc.label = item.label?.trim() || 'Guide';
      break;
    case 'landingPage':
    case 'videoPage':
    case 'articlePage':
      doc.title = item.title?.trim();
      doc.description = item.description?.trim();
      doc.label = item.label?.trim();
      doc.image = getTemplateSocialCardAsset(item);
      break;
    case 'customGridContent':
      doc.title = item.title?.trim();
      doc.description = item.description?.trim();
      doc.image = item.image;
      doc.linkExternal = item.external;
      break;
    default:
      break;
  }

  doc.url = getLinkForSearchHit(item);
  return doc.title ? doc : null;
}

export function getLinkForSearchHit(doc: any): string {
  const ctype = doc.contentType;
  if (ctype === 'blogPost') {
    return `/blog/${doc.urlSlug}`;
  }
  if (ctype === 'video') {
    return `/videos/all-videos/${doc.urlSlug}`;
  }
  if (ctype === 'videoPage') {
    return `/video/${doc.urlSlug}`;
  }
  if (ctype === 'faq') {
    return `/help/article/${doc.urlSlug}`;
  }
  if (ctype === 'landingPage') {
    return `/${doc.urlSlug}`;
  }
  if (ctype === 'articlePage') {
    return `/blog/${doc.urlSlug}`;
  }
  if (ctype === 'event') {
    return fixHref(doc.url) || '/';
  }
  if (ctype === 'customGridContent') {
    return fixHref(doc.url) || '/';
  }
  return '/';
}

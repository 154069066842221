// ignore-string-externalization

import React from 'react';
import { marked } from 'marked';
import { renderToString } from 'react-dom/server';
import { UnstyledLink as Link } from '../ui';
import {
  Table,
  TableHeaderCell,
  TableRow,
  TableCell,
  Text,
} from '@spotify-internal/encore-web';
import styled from 'styled-components';

import { adjustVariant } from '../../../features/src/encore4To9VariantConvert';

const StyledTableCell = styled(TableCell)`
  text-align: start;
`;
const StyledTableHeaderCell = styled(TableHeaderCell)`
  text-align: start;
`;

export class DefaultRenderer extends marked.Renderer {
  link(...args: any[]) {
    return renderLink.apply(this, args as [any, any, any]);
  }

  paragraph(text: string) {
    const str = spotifyWidgetParagraph(text);
    return (super.paragraph as any)(str);
  }

  heading(text: string, level: 1 | 2 | 3 | 4) {
    const variant = `heading${level}` as
      | 'heading1'
      | 'heading2'
      | 'heading3'
      | 'heading4';

    const { variant: variantAdjusted, ...styles } = adjustVariant(variant);
    // wrapping this (weirdly) only to silence Encore v5 codemod. Feel free to unwrap and pass directly once we're on Encore v5
    const variantProp = { ['variant']: variantAdjusted };

    const typeAs = `h${level}` as 'h1' | 'h2' | 'h3' | 'h4';
    return renderToString(
      <Text
        as={typeAs}
        {...variantProp}
        style={styles}
        dangerouslySetInnerHTML={{ __html: text }}
      />,
    );
  }

  hr() {
    return '';
  }

  table(header: string, body: string) {
    return renderTable(header, body);
  }
}

/**
 * Renders a link, external by default
 *
 * @example: [Spotify for Artists](https://artists.spotify.com/ "internal")
 */
export const renderLink = (href: string, title: string, text: string) => {
  return renderToString(
    <Link
      external={title !== 'internal'}
      track={['External Link', `Content Link - ${href}`]}
      href={href}
    >
      <span dangerouslySetInnerHTML={{ __html: text || title }} />
    </Link>,
  );
};

export const spotifyWidgetParagraph = (text: string) => {
  if (text.indexOf('[play-button') === 0) {
    const [, uri, compact] =
      /\[play-button\s+(spotify:[\w:]+)(?:\s+(compact))?\]/g.exec(
        text,
      ) as any[];
    const height = compact === 'compact' ? 80 : 380;

    return `<iframe
      src="https://open.spotify.com/embed?uri=${uri}"
      width="300"
      height="${height}"
      frameborder="0"
      allowtransparency="true"
      allow="encrypted-media">
    </iframe>`;
  }

  return text;
};

// render simple markdown-produced table as encore table
function renderTable(header: string, body: string) {
  const headerCells = header
    .split(/<th>(.*)<\/th>/)
    .map(val => val?.replace(/<[/]?t[rh]{1}>/, '').trim())
    .filter(Boolean)
    .map((val, i) => (
      <StyledTableHeaderCell
        key={`th-${i}`}
        dangerouslySetInnerHTML={{ __html: val }}
      />
    ));

  const bodyRows = body
    .split(/<tr>/)
    .map(val => val?.replace('</tr>', '').trim())
    .filter(Boolean)
    .map((val, i) => {
      const cells = val
        .split(/<td>(.*)<\/td>/)
        .map(cellVal => cellVal?.trim())
        .filter(Boolean)
        .map((cellVal, j) => (
          <StyledTableCell
            key={`td-${i}-${j}`}
            dangerouslySetInnerHTML={{ __html: cellVal }}
          />
        ));
      return <TableRow key={`tr-${i}`}>{cells}</TableRow>;
    });

  const output = (
    <Table>
      <thead>
        <TableRow>{headerCells}</TableRow>
      </thead>
      <tbody>{bodyRows}</tbody>
    </Table>
  );

  return renderToString(output);
}
